export default {
	agency_management:  {
		title:      'Agency Management',
		create_new: 'Maak een nieuwe agency',
	},
	user_management:    {
		title: 'Portal Users',
	},
	backup_management:  {
		title: 'Backup Management',
	},
	server_groups:      {
		title: 'Server Groups',
	},
	features:           {
		title: 'Features',
	},
	server_types:       {
		title: 'Server Types',
	},
	notifications:      {
		title: 'Notifications',
	},
	portal:             {
		user: 'Portal user',
	},
	contact_details:    {
		title: 'Contact informatie',
	},
	app_authentication: {
		title: 'App Authenticatie',
	},
	download:           {
		app: 'Download de app',
	},

	agency: {
		regenerate_unique_id: 'Uniek ID opnieuw genereren',

		details: {
			settings: 'Instellingen',
		},
	},
};
