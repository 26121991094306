export default {
	add:                'Add server',
	modify:             'Modify server',
	shutdown:           'Shutdown server',
	dashboard:          {
		title: 'Dashboard',
	},
	create:             {
		delivery_time: {
			cloud:   'Delivery times Cloud servers: usually a few minutes',
			default: 'Please select a server type to view server delivery times',
			robot:   'Expected delivery time: around {time} minutes',
		},
		disclaimer:    'All prices incl. 21% VAT. Our <a href="{href}" class="corebox-link" target="_blank">terms and conditions</a> apply.',
	},
    destroy_confirm: {
        title: 'Delete server',
        content: 'Are you sure you want to delete server `{server}`?',
    },
	hosts:              {
		title:        'Hosts',
		add:          'Add host',
		destroy:      'Delete host',
        destroy_confirm: {
            title: 'Delete server host',
            content: 'Are you sure you want to delete host `{host}`?',
        },
		show:         'Host',
		ssl_settings: {
			title: 'SSL settings',
			hsts:  'Hsts',
		},
		boot:         'Start host',
		shutdown:     'Shutdown host',
		status:       {
			'inactive': 'Inactive',
			'active':   'Active',
		},
	},
	aliases:            {
		title:   'Aliases',
		add:     'Add alias',
		destroy: 'Delete alias',
		show:    'Alias',
		status:  {
			'inactive': 'Inactive',
			'active':   'Active',
		},
	},
	server_users:       {
		title:   'Server users',
		add:     'Add server user',
		show:    'Server user',
		saved:   'Server user saved',
		updated: 'Server user updated',
		deleted: 'Server user deleted',
		delete:  {
			title:   'Delete server user',
			content: 'Are you sure you want to delete server user {user}?',
			user:    'Delete user',
		},
	},
    php_versions: {
        php56: 'PHP 5.6',
        php70: 'PHP 7.0',
        php71: 'PHP 7.1',
        php72: 'PHP 7.2',
        php73: 'PHP 7.3',
        php74: 'PHP 7.4',
        php80: 'PHP 8.0',
        php81: 'PHP 8.1',
        php82: 'PHP 8.2',
        php83: 'PHP 8.3',
    },
    advanced_settings: {
        title: 'Advanced settings',
    },
    webserver: {
        title: 'Webserver',
        apache: 'Apache',
        nginx: 'Nginx',
    },
    php_version: {
        title: 'PHP version',
    },
    database_version: {
        title: 'Database',
        mysql_57: 'MySQL 5.7',
        mysql_80: 'MySQL 8.0',
    },
    server_os: {
        title: 'Server OS',
        placeholder: 'Ubuntu 18.04 LTS',
    },
    server_location: {
        title: 'Server location',
        placeholder: 'Finldand',
    },
	ftp_users:          {
		title:   'FTP users',
		add:     'Add FTP user',
		destroy: 'Delete FTP user',
		show:    'FTP user',
		status:  {
			'inactive': 'Inactive',
			'active':   'Active',
		},
	},
	users:              {
		add:  'Add user',
		show: 'User',
	},
	email:              {
		title: 'Email',

		mail_server: {
			title:   'Mailserver',
			created: 'Mailserver created',
			updated: 'Mailserver updated',
		},
	},
	services:           {
		title:               'Services',
		install:             'Install services',
		configured_versions: 'Configured versions',
	},
	features:           {
		title: 'Features',
	},
	monitoring:         {
		title: 'Monitoring',
        standard_checks: 'Standard checks'
	},
	ip_addresses:       {
		title:   'IP addresses',
		add:     'Add IP address',
        show:    'IP address',
		deleted: 'Server user deleted',
		delete:  {
			title:      'Delete ip address',
			content:    'Are you sure you want to delete ip address {ipAddress} of type {type}?',
			ip_address: 'Delete ip address',
		},
	},
	deployments:        {
		title: 'Deployments',
        show: 'Deployment details',
		created_at: 'Date/Time',
		description: 'Description (tags)',
		type: 'Type',
		duration: 'Duration',
		status: 'Status',
		details: 'Details',
        statuses: {
            planned: 'planned',
            failed: 'failed',
            running: 'running',
            canceled: 'canceled',
            success: 'success'
        },
	},
	backups:            {
		title: 'Back-ups',
	},
	settings:           {
		title: 'Settings',
		note:  'by linking the server to another server group, this will also be processed in the billing overview.',
	},
	customer:           {
		assign: 'Assign customer',
	},
	change_report:      'Change report',
	status:             {
		unknown:      'Unknown',
		running:      'running',
		deploying:    'deployment running...',
		'failed deployment': 'failed deployment',
		off:          'offline',
		initializing: 'initializing',
		starting:     'starting',
	},
	files:              'Files',
	database:           'Database',
	filter_selection:   'Filter selection',
	ssh_key_management: 'SSH Key Management',
	server_user:        'Server user',
	ssl_options:        {
		none:        'None',
		custom:      'Custom',
		letsencrypt: 'Let\'s Encrypt',
	},
	ssl:                {
		certificate_info: 'Certificate information',
		issuer:           'Issuer',
		domain:           'Domain',
		status_title:     'Status',
		status:           {
			valid:   'Valid',
			warning: 'Warning',
		},
	},
    server_installation : {
        title: 'Server installation',
        go_to_server: 'Go to server',
        services_installation: {
            title: 'Installation service',
            see_more: 'See more 1 click installation options',

            services: {
                vcs: 'Git',
                magento: 'Magento',
                wordpress: 'Wordpress',
                showpare: 'Shopware',
                laravel: 'Laravel',
                akeneo: 'Akeneo',

                vcs_variants: {
                    gitlab: 'Gitlab',
                    github: 'Github',
                    bitbucket: 'Bitbucket',
                    custom: 'Custom'
                }
            },

            hostname: {
                title: 'Hostname',
                placeholder: 'user/repository'
            },

            branch: {
                title: 'Branch',
                placeholder: 'master'
            },

            composer: {
                install_deps: 'Install composer dependencies',
            },

            install: 'Install repository',
        }
    },
    server_maintenance: {
        title: 'Server Maintenance',
        scheduled_at: 'Maintenance date',
        approved_by: 'Approved by',
        change_log: 'Change log',
        change_log_not_available: 'Not available',
        status: 'Status',

        statuses: {
            failed: 'Failed',
            awaiting_approval: 'Awaiting approval',
            approved: 'Approved',
            dispatched: 'Dispatched',
            first_candidate_finished: 'First candidate finished',
            finished: 'Finished',
            canceled: 'Canceled',
        },

        actions: {
            approve: 'Approve',
            reject: 'Reject',
        },

        jobs: {
            excluded: 'Ruled out',
            planned_at: 'Planned on',
            started_at: 'Started on',
            finished_at: 'Finished on',

            statuses: {
                failed: 'Failed',
                scheduled: 'Scheduled',
                running: 'Running',
                finished: 'Finished',
                canceled: 'Canceled',
            },
        }
    },
    configuration: {
        configuration_changed: {
            title: 'Server type changed',
            content: 'The configuration of the server type has been changed. The server will be turned off and rescaled. Disclaimer: disk space can\'t be reduced.',
            submit: 'Save and rescale',
        }
    }
};
