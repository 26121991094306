export default {
    dashboard:     {
        title: 'Dashboard',
    },
    hosts:         {
        title: 'Hosts',
    },
    aliases:       {
        title: 'Aliases',
    },
    server_users:  {
        title: 'Server users',
    },
    ftp_users:     {
        title: 'FTP users',
    },
    email:         {
        title: 'Email',
    },
    features:      {
        title: 'Features',
    },
    monitoring:    {
        title: 'Monitoring',
    },
    ip_addresses:  {
        title:  'IP addresses',
        add:    'Add IP address',
        delete: 'Delete IP address'
    },
    deployments:   {
        title: 'Deployments',
    },
    backups:       {
        title: 'Back-ups',
    },
    settings:      {
        title: 'Settings',
    },
    customer:      {
        assign: 'Assign customer',
    },
    change_report: 'Change report',
    files:         'Bestanden',
    database:      'Database',
};
