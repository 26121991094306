export default {
    labels:       {
        name:          'Naam',
        surname:       'Achternaam',
        email:         'Emailadres',
        role:          'Rol naam',
        account:       {
            active: 'Account actief',
        },
        twofactor:     {
            active: '2FA actief',
        },
        slack:         {
            id: 'Slack ID',
        },
        notifications: {
            general:   'Algemene notificaties',
            technical: 'Technische notificaties',
        },
        save:          'Opslaan',
    },
    placeholders: {
        name:    'Naam',
        surname: 'Achternaam',
        email:   'Emailadres',
        role:    'Rol',
        slack:   {
            id: 'Slack ID',
        },
        all: 'Alle',
    },
};
