export default {
	search: {
		index: 'Search',
	},

	admin: {
		index: 'Admin',

		agencies: {
			index:  'Agencies',
			create: 'Add agency',
			show:   '{agency}',
		},

		users: {
			index:  'Portal users',
			create: 'Add portal user',
			show:   '{user}',
		},

		backup_servers: {
			index:  'Manage back-up servers',
			create: 'Add backup server',
			show:   '{backupServer}',
		},

		server_groups: {
			index:  'Server groups',
			create: 'Add server group',
			show:   '{serverGroup}',
		},

		services: {
			index:  'Services',
			create: 'Add service',
			edit:   '{service} configuration',
		},

		server_types: {
			index: 'Server types',
			show:  '{server_type}',
		},

        roles: {
            index:  'Roles & rights',
            create: 'Add role & rights',
            show:   '{role}',
            edit:   '{role}',
        },

        sla_bundles: {
            index: 'SLA bundles',
        },

        sla_bundle_types: {
            index: 'SLA bundles types',
            create: 'Create SLA bundle type',
            show: '{sla_bundle_type}',
        },
    },

	customers: {
		index:  'Customers',
		create: 'Add customer',
		show:   '{customer}',
		edit:   '{customer}',
	},

	monitoring: {
		dashboard: 'Dashboard',
	},

	servers: {
		index:  'Server management',
		create: 'Add server',
		show:   '{server}',
		edit:   '{server}',

		hosts: {
			index:  'Hosts',
			create: 'Add host',
			show:   '{host}',
			edit:   '{host}',
		},

		aliases: {
			index:  'Aliases',
			create: 'Add alias',
			show:   '{alias}',
			edit:   '{alias}',
		},

		server_users: {
			index:  'Server users',
			create: 'Add server user',
			show:   '{user}',
		},

		ftp_users: {
			index:  'FTP users',
			create: 'Add FTP user',
			show:   '{ftpUser}',
			edit:   '{ftpUser}',
		},

		mail: {
			index: 'Email',
		},

		server_service: {
			index: 'Services',
			show:  'server_service Show',
			edit:  'server_service Edit',
		},

		ip_addresses: {
			index:  'IP addresses',
			create: 'Add IP address',
			show:   'Update ip address',
		},

		deployments: {
			index: 'Deployments',
			show:  'Deployments detail?',
		},

		server_backups: {
			index: 'Back-ups',
		},

		settings: {
            index: 'Settings',
        },

        server_maintenance: {
            index: 'Server maintenance',
            show: 'Server maintenance #{serverMaintenance}',
        },
        centreon_configuration:{
            index: 'Monitoring'
        }
	},

	users: {
		ssh_keys: {
			index:  'User SSH Key Management',
			create: 'Add SSH key',
			show:   '{sshKey}',
		},
	},
};
