import admin    from './admin.js';
import form     from './form.js';
import generics from './generics.js';
import profile  from './profile.js';
import servers  from './servers.js';

export default {
    admin,
    form,
    profile,
    servers,

    ...generics,
};
