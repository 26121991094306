import './bootstrap';
import '../css/reset.css';
import '../css/app.css';
import '../css/utilities.css';
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'flag-icons/css/flag-icons.min.css';
import 'intl-tel-input/build/css/intlTelInput.css';

import * as Sentry from '@sentry/vue';
import {createApp, h} from 'vue';
import {createInertiaApp, Link} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import ToastPlugin from 'vue-toast-notification';
import i18n from '@/i18n';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Corebox';

createInertiaApp({
	title:   (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({el, App, props, plugin}) {
		const app = createApp({render: () => h(App, props)})
			.use(plugin)
			.use(ZiggyVue)
			.use(ToastPlugin)
			.use(i18n)
			.component('Link', Link);

		if (!document.location.href.includes('.test') && import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
			Sentry.init({
				app,
				dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
				tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
				// Session Replay
				replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
				replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			});

			// Sentry configuration
			app.mixin(Sentry.createTracingMixins({trackComponents: true}));
			Sentry.attachErrorHandler(app, {logErrors: true});
		}

		app.mount(el);

		return app;
	},
	progress: {
		color: '#4B5563',
	},
});
